@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes shimmer {
    0% {
      transform: translateX(-150%);
    }
    100% {
      transform: translateX(150%);
    }
  }

  .animate-shimmer {
    animation: shimmer 1.5s infinite linear;
  }
